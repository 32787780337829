import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { ReactComponent as TickMark } from '../../assets/TickMark.svg';
import classNames from 'classnames';
const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const SectionFeaturesMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }
  const filterConfig = config.custom.filters;

  //category feature
  const productCategoryOptions = findOptionsForSelectFilter(
    'productCategory',
    filterConfig
  );

  const listingCategory = publicData?.productCategory
    ? productCategoryOptions.filter(
        c => c.value === publicData?.productCategory
      )[0].label
    : undefined;

  //sub-category feature
  const foodDrinksSubCategoryOptions = findOptionsForSelectFilter(
    'foodDrinksSubCategory',
    filterConfig
  );
  const traditionalSubCategoryOptions = findOptionsForSelectFilter(
    'traditionalSubCategory',
    filterConfig
  );
  const clothingSubCategoryOptions = findOptionsForSelectFilter(
    'clothingSubCategory',
    filterConfig
  );
  const jewellerySubCategoryOptions = findOptionsForSelectFilter(
    'jewellerySubCategory',
    filterConfig
  );
  const beautySubCategoryOptions = findOptionsForSelectFilter(
    'beautySubCategory',
    filterConfig
  );
  const booksSubCategoryOptions = findOptionsForSelectFilter(
    'booksSubCategory',
    filterConfig
  );
  const homeDecorSubCategoryOptions = findOptionsForSelectFilter(
    'homeDecorSubCategory',
    filterConfig
  );
  const furnitureSubCategoryOptions = findOptionsForSelectFilter(
    'furnitureSubCategory',
    filterConfig
  );
  const businessServicesSubCategoryOptions = findOptionsForSelectFilter(
    'businessServicesSubCategory',
    filterConfig
  );
  const restaurantCafeSubCategoryOptions = findOptionsForSelectFilter(
    'restaurantCafeSubCategory',
    filterConfig
  );

  const listingSubCategory = publicData?.productSubCategory
    ? publicData?.productCategory === 'foodDrinks'
      ? foodDrinksSubCategoryOptions?.find(
          f => publicData?.productSubCategory === f.value
        ).label
      : publicData?.productCategory === 'traditional'
      ? traditionalSubCategoryOptions?.find(
          f => publicData?.productSubCategory === f.value
        ).label
      : publicData?.productCategory === 'clothing'
      ? clothingSubCategoryOptions?.find(
          f => publicData?.productSubCategory === f.value
        ).label
      : publicData?.productCategory === 'jewellery'
      ? jewellerySubCategoryOptions?.find(
          f => publicData?.productSubCategory === f.value
        ).label
      : publicData?.productCategory === 'beauty'
      ? beautySubCategoryOptions?.find(
          f => publicData?.productSubCategory === f.value
        ).label
      : publicData?.productCategory === 'books'
      ? booksSubCategoryOptions?.find(
          f => publicData?.productSubCategory === f.value
        ).label
      : publicData?.productCategory === 'homeDecor'
      ? homeDecorSubCategoryOptions?.find(
          f => publicData?.productSubCategory === f.value
        ).label
      : publicData?.productCategory === 'furniture'
      ? furnitureSubCategoryOptions?.find(
          f => publicData?.productSubCategory === f.value
        ).label
      : publicData?.productCategory === 'businessServices'
      ? businessServicesSubCategoryOptions?.find(
          f => publicData?.productSubCategory === f.value
        ).label
      : publicData?.productCategory === 'restaurantsCafe'
      ? restaurantCafeSubCategoryOptions?.find(
          f => publicData?.productSubCategory === f.value
        ).label
      : undefined
    : undefined;

  // console.log(303, publicData.productSubCategory, listingSubCategory);

  //clothingSize feature
  const clothingSizeOptions = findOptionsForSelectFilter(
    'clothingSize',
    filterConfig
  );
  const clothingSizes = publicData?.clothingSize
    ? clothingSizeOptions
        .filter(f => publicData?.clothingSize?.includes(f.value))
        .map(l => l.label)
        .toString()
    : [];

  //Dinning type feature

  const dinningTypeOptions = findOptionsForSelectFilter(
    'dinningOptions',
    filterConfig
  );
  const dinningTypes = publicData?.dinningOptions
    ? dinningTypeOptions
        .filter(f => publicData?.dinningOptions?.includes(f.value))
        .map(l => l.label)
        .toString()
    : undefined;

  //Accomodation feature

  const accommodationFeatureOptions = findOptionsForSelectFilter(
    'accommodationFeatures',
    filterConfig
  );
  const accommodationFeatures = publicData?.accommodationFeatures
    ? accommodationFeatureOptions
        .filter(f => publicData?.accommodationFeatures?.includes(f.value))
        .map(l => l.label)
    : // .toString()
      undefined;
  // const accommodationFeaturesList = _.isArray(accommodationFeatures)
  //   ? accommodationFeatures
  //   : accommodationFeatures.split(',');
  // console.log(
  //   454,
  //   accommodationFeatures,
  //   accommodationFeaturesList
  // );
  //bedroom number feature

  const bedroomNumbersOptions = findOptionsForSelectFilter(
    'bedroomNumbers',
    filterConfig
  );
  const bedroomNumbers = publicData?.bedroomCount
    ? bedroomNumbersOptions.find(f => publicData?.bedroomCount === f.value)
        .label
    : undefined;

  //bathroom number feature

  const bathroomNumbersOptions = findOptionsForSelectFilter(
    'bathroomNumbers',
    filterConfig
  );
  const bathroomNumbers = publicData?.bathroomCount
    ? bathroomNumbersOptions.find(f => publicData?.bathroomCount === f.value)
        .label
    : undefined;

  //available days
  const availableWeekDays = publicData.availability || [];

  const availableDays = WEEKDAYS.filter(w => availableWeekDays.includes(w));

  // console.log(858, WEEKDAYS, availableWeekDays, availableDays);

  // const  availableDays

  // const selectedOptions =
  //   publicData && publicData.yogaStyles ? publicData.yogaStyles : [];
  // const selectedConfigOptions = options.filter(o =>
  //   selectedOptions.find(s => s === o.key)
  // );
  const providerAddress = publicData?.location || {};
  const preDefinedSizes = clothingSizeOptions.map(s => s.label) || [];

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <div className={css.featureDiv}>
        {listingCategory ? (
          <div className={css.featureDetail}>
            <span>Category</span>
            <span>{listingCategory}</span>
          </div>
        ) : null}
        {listingSubCategory ? (
          <>
            <span className={css.hDivider} />
            <div className={css.featureDetail}>
              <span>Sub-Category</span>
              <span>{listingSubCategory}</span>
            </div>
          </>
        ) : null}
        {/* {clothingSizes ? (
          <>
            <span className={css.hDivider} />
            <div className={css.featureDetail}>
              <span>Clothing Size</span>
              <span>{clothingSizes}</span>
            </div>
          </>
        ) : null} */}

        {clothingSizes?.length > 0 ? (
          <>
            <span className={css.hDivider} />
            <div className={css.featureDetail}>
              <span>Clothing Size</span>
              <div className={css.avlDiv}>
                {preDefinedSizes.map(s => (
                  <span
                    key={s}
                    className={classNames(css.wkday, {
                      [css.avlDay]: clothingSizes.includes(s),
                    })}
                  >
                    <TickMark
                      className={
                        clothingSizes.includes(s)
                          ? css.avlsvgCont
                          : css.notavlsvgCont
                      }
                    />
                    {clothingSizes.includes(s) ? s : s.split('(')[0].trim()}
                  </span>
                ))}
              </div>
            </div>
          </>
        ) : null}

        {dinningTypes ? (
          <>
            <span className={css.hDivider} />
            <div className={css.featureDetail}>
              <span>Dinning Options</span>
              <span>{dinningTypes}</span>
            </div>
          </>
        ) : null}
        {accommodationFeatures ? (
          <>
            <span className={css.hDivider} />
            <div className={css.accoFeatureDetail}>
              <span>Accommodation Features</span>
              <ul className={css.listStyle}>
                {accommodationFeatures.map(l => {
                  return <li key={l}>{l}</li>;
                })}
                {/* {accommodationFeaturesList.map(l => {
                  return <li key={l}>{l}</li>;
                })} */}
              </ul>
            </div>
          </>
        ) : null}
        {bedroomNumbers ? (
          <>
            <span className={css.hDivider} />
            <div className={css.featureDetail}>
              <span>Bedroom Numbers</span>
              <span>{bedroomNumbers}</span>
            </div>
          </>
        ) : null}
        {bathroomNumbers ? (
          <>
            <span className={css.hDivider} />
            <div className={css.featureDetail}>
              <span>Bathroom Numbers</span>
              <span>{bathroomNumbers}</span>
            </div>
          </>
        ) : null}
        {availableDays?.length > 0 ? (
          <>
            <span className={css.hDivider} />
            <div className={css.featureDetail}>
              <span>Availability</span>
              <div className={css.avlDiv}>
                {WEEKDAYS.map(d => (
                  <span
                    key={d}
                    className={classNames(css.wkday, {
                      [css.avlDay]: availableDays.includes(d),
                    })}
                  >
                    <TickMark
                      className={
                        availableDays.includes(d)
                          ? css.avlsvgCont
                          : css.notavlsvgCont
                      }
                    />
                    {d}
                  </span>
                ))}
              </div>
            </div>
          </>
        ) : null}
        {providerAddress ? (
          <>
            <span className={css.hDivider} />
            <div className={css.featureDetail}>
              <span>Address</span>
              <span>{providerAddress.address}</span>
            </div>
          </>
        ) : null}
      </div>

      {/* <PropertyGroup
        id="ListingPage.yogaStyles"
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions.length > 5}
      /> */}
    </div>
  );
};

export default SectionFeaturesMaybe;
